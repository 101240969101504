import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PhotoMenu from "../components/photomenu"
import "../components/sub.css"
import Workskv from "../images/works-kv.png"
import Noimg from "../images/noimg.png"
import Works_1 from "../images/works_1.png"
import Works_2 from "../images/works_2.png"
import Works_3 from "../images/works_3.png"
import Works_4 from "../images/works_4.png"

const Works = () => (
  <Layout>
    <Seo title="施工情報 - WORKS" />
    <img className="img_fluid w-100 sub_kv" src={Workskv} />
    <div className="container">
      <div className="title-content">
        <h1 className="syouei-font-en syouei-font-bold main-title">WORKS</h1>
        <h2 className="syouei-font-bold sub-title-ja">施工情報</h2>
      </div>
      <div className="p-0 p-md-3">
        <div className="row syouei-works-border pt-3 pb-5 pt-md-3 pb-md-3">
          <div className="d-block d-md-none">
            <p className="syouei-works-name syouei-font-regular">油配管工事</p>
          </div>
          <div className="col-12 col-xl-3 col-lg-4 col-md-5">
            <img className="syouei-woeks-img" src={Works_1} />
          </div>
          <div className="col-12 col-xl-9 col-lg-8 col-md-7 syouei-works-table">
            <div className="syouei-works-text pt-3 pt-xl-0">
              <p className="d-none d-md-block syouei-works-name syouei-font-regular">
                油配管工事
              </p>
              <p className="syouei-woeks-detail">
                ガソリンスタンド、自家給油所、石油プラントの油配管（ガソリン、軽油、重油等）の敷設作業
              </p>
            </div>
          </div>
        </div>
        <div className="row syouei-works-border pt-5 pb-5 pt-md-3 pb-md-3">
          <div className="d-block d-md-none">
            <p className="syouei-works-name syouei-font-regular">
              エアー・泡消火配管工事作業・アドブルー
            </p>
          </div>
          <div className="col-12 col-xl-3 col-lg-4 col-md-5">
            <img className="syouei-woeks-img" src={Works_2} />
          </div>
          <div className="col-12 col-xl-9 col-lg-8 col-md-7 syouei-works-table">
            <div className="syouei-works-text pt-3 pt-xl-0">
              <p className="d-none d-md-block syouei-works-name syouei-font-regular">
                エアー・泡消火配管工事作業・アドブルー
              </p>
              <p className="syouei-woeks-detail">
                エアー　　　　：ガソリンスタンド等にて洗車機、吹上スペース、ピット室等に配管の敷設作業
                <br />
                泡消火配管　　：セルフスタンドにおいて消防法で取り決めされた機器配管の敷設
                <br />
                アドブルー配管：尿素水の供給機器、タンクの取付配管の敷設作業（主にSAS配管
                ）
              </p>
            </div>
          </div>
        </div>
        <div className="row syouei-works-border pt-5 pb-5 pt-md-3 pb-md-3">
          <div className="d-block d-md-none">
            <p className="syouei-works-name syouei-font-regular">
              土木・外構工事
            </p>
          </div>
          <div className="col-12 col-xl-3 col-lg-4 col-md-5">
            <img className="syouei-woeks-img" src={Works_3} />
          </div>
          <div className="col-12 col-xl-9 col-lg-8 col-md-7 syouei-works-table">
            <div className="syouei-works-text pt-3 pt-xl-0">
              <p className="d-none d-md-block syouei-works-name syouei-font-regular">
                土木・外構工事
              </p>
              <p className="syouei-woeks-detail">
                防火塀、洗車機、土間コンクリート等の外構作業が主な工事
              </p>
            </div>
          </div>
        </div>
        <div className="row syouei-works-border pt-5 pb-5 pt-md-3 pb-md-3">
          <div className="d-block d-md-none">
            <p className="syouei-works-name syouei-font-regular">補修工事</p>
          </div>
          <div className="col-12 col-xl-3 col-lg-4 col-md-5">
            <img className="syouei-woeks-img" src={Works_4} />
          </div>
          <div className="col-12 col-xl-9 col-lg-8 col-md-7 syouei-works-table">
            <div className="syouei-works-text pt-3 pt-xl-0">
              <p className="d-none d-md-block syouei-works-name syouei-font-regular">
                補修工事
              </p>
              <p className="syouei-woeks-detail">
                ガソリンスタンド建屋の修繕作業（雨漏り補修、クロス張替え、間仕切り、住設機器交換）
                <br />
                土間補修、小溝補修及び交換、MHパッキン交換等
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="syouei-sub syouei-works">
      <PhotoMenu />
    </div>
  </Layout>
)

export default Works
