import * as React from "react"
import PropTypes, { array } from "prop-types"
import { Link } from "gatsby"

import white_arrow from "../images/white_arrow.png"

export default function PhotoMenu() {
  return (
    <div className="syouei-page-link syouei-content-mb">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12 syouei-link syouei-link-about gx-0 gx-md-3 mb-3 mb-md-0">
            <Link to="/aboutus" className="syouei-white-a syouei-photomenu">
              <div className="syouei-photomenu-div">
                <img src={white_arrow} className="syouei-white-arrow" />
                <p className="syouei-link-en ps-4 syouei-font-en syouei-font-medium">
                  ABOUT US
                </p>
                <p className="syouei-link-jp ps-4">会社概要</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-12 syouei-link syouei-link-careers gx-0 gx-md-3 mb-3 mb-md-0">
            <Link to="/careers" className="syouei-white-a syouei-photomenu">
              <div className="syouei-photomenu-div">
                <img src={white_arrow} className="syouei-white-arrow" />
                <p className="syouei-link-en ps-4 syouei-font-en syouei-font-medium">
                  CAREERS
                </p>
                <p className="syouei-link-jp ps-4">採用情報</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-12 syouei-link syouei-link-works gx-0 gx-md-3 mb-3 mb-md-0">
            <Link to="/works" className="syouei-white-a syouei-photomenu">
              <div className="syouei-photomenu-div">
                <img src={white_arrow} className="syouei-white-arrow" />
                <p className="syouei-link-en ps-4 syouei-font-en syouei-font-medium">
                  WORKS
                </p>
                <p className="syouei-link-jp ps-4">施工情報</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
